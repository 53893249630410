import type {
  CreateNotificationEventContent,
  GeneralInformationUpdateContent,
  ProjectEventType,
  ProjectMilestonesUpdateEventContent,
  ProjectStatusDataUpdateContent,
  ProjectStoriesUpdateEventContent,
  ProjectStoryTagCreateEventContent,
  ProjectStoryTagDeleteEventContent,
  ProjectStoryTagUpdateEventContent,
  ProjectSubscribeCommand,
  ProjectUnsubscribeCommand,
  ProjectUpdateEventContent,
  SignInCommand,
  SignInErrorEvent,
  SignInSuccessEvent,
  TaigaAction,
  UUIDB64,
  UserEventType,
  WorkspaceCreateNotificationEventContent,
  WorkspaceEventType,
  WorkspaceSubscribeCommand,
  WorkspaceUnsubscribeCommand,
} from '$lib/types/taiga-events';
import { getUserTokenFromBrowser } from '$src/axios/interceptors';
import { env } from '$src/env';
import { get, writable } from 'svelte/store';

// Types

// User event CBs
type ProjectStatusDataUpdateEventCB = (content: ProjectStatusDataUpdateContent) => void;
type WorkspaceGeneralInformationUpdateEventCB = (content: GeneralInformationUpdateContent) => void;
type ProjectStoriesUpdateEventCB = (content: ProjectStoriesUpdateEventContent) => void;
type CreateNotificationEventCB = (content: CreateNotificationEventContent) => void;

type UserEventCB =
  | ProjectStatusDataUpdateEventCB
  | WorkspaceGeneralInformationUpdateEventCB
  | ProjectStoriesUpdateEventCB
  | CreateNotificationEventCB;

interface UserEventSubscription {
  type: UserEventType;
  cb: UserEventCB;
}

// Project event CBs
type ProjectUpdateEventCB = (content: ProjectUpdateEventContent) => void;
type ProjectMilestonesUpdateEventCB = (content: ProjectMilestonesUpdateEventContent) => void;
type ProjectUpdateStoryTagEventCB = (content: ProjectStoryTagUpdateEventContent) => void;
type ProjectDeleteStoryTagEventCB = (content: ProjectStoryTagDeleteEventContent) => void;
type ProjectCreateStoryTagEventCB = (content: ProjectStoryTagCreateEventContent) => void;

type ProjectEventCB =
  | ProjectUpdateEventCB
  | ProjectMilestonesUpdateEventCB
  | ProjectUpdateStoryTagEventCB
  | ProjectDeleteStoryTagEventCB
  | ProjectCreateStoryTagEventCB;

interface ProjectEventSubscription {
  project: UUIDB64;
  type: ProjectEventType;
  cb: ProjectEventCB;
}

// Workspace event CBs
type WorkspaceCreateNotificationEventCB = (content: WorkspaceCreateNotificationEventContent) => void;

type WorkspaceEventCB = WorkspaceCreateNotificationEventCB;

interface WorkspaceEventSubscription {
  workspace: UUIDB64;
  type: WorkspaceEventType;
  cb: WorkspaceEventCB;
}

// Constants

const RECONNECT_INTERVAL = 10000;

const PROJECTS_UPDATE_EVENT = 'projects.update';
const PROJECT_MILESTONES_UPDATE_EVENT = 'projects.milestones.update';
const GENERAL_INFORMATION_UPDATE_EVENT = 'workspacememberships.update.general_information';
const PROJECT_STATUS_DATA_UPDATE_EVENT = 'projectroles.status_data.update';
const PROJECT_STORIES_UPDATE_EVENT = 'projects.stories.update';
const CREATE_NOTIFICATION_EVENT = 'notifications.create';
const WORKSPACE_CREATE_NOTIFICATION_EVENT = 'workspace.notifications.create';
const CREATE_STORY_TAG_EVENT = 'story_tags.create';
const UPDATE_STORY_TAG_EVENT = 'story_tags.update';
const DELETE_STORY_TAG_EVENT = 'story_tags.delete';

// Stores

const taigaWS = writable<WebSocket | null>(null);
const userEventSubscriptions = writable<UserEventSubscription[]>([]);
const projectEventSubscriptions = writable<ProjectEventSubscription[]>([]);
const workspaceEventSubscriptions = writable<WorkspaceEventSubscription[]>([]);

// Utils

const isSignInEvent = (data: any): data is SignInSuccessEvent | SignInErrorEvent => {
  return data.type === 'action' && data.action?.command === 'signin';
};

const isSignInErrorEvent = (data: any): data is SignInErrorEvent => {
  return isSignInEvent(data) && data.status === 'error';
};

const isEvent = (data: any): boolean => {
  return data?.type === 'event';
};

const addProjectEventSubscription = (projectID: UUIDB64, cb: ProjectEventCB, type: ProjectEventType) => {
  const existingSubscriptions = get(projectEventSubscriptions);
  projectEventSubscriptions.set([...existingSubscriptions, { project: projectID, type, cb }]);
};

const removeProjectEventSubscription = (projectID: UUIDB64, cb: ProjectEventCB, type: ProjectEventType) => {
  const existingSubscriptions = get(projectEventSubscriptions);
  const subscriptionsToBeRemoved = existingSubscriptions.filter(
    (s) => s.project === projectID && s.cb === cb && s.type === type
  );
  projectEventSubscriptions.set(existingSubscriptions.filter((s) => !subscriptionsToBeRemoved.includes(s)));
};

const addWorkspaceCreateNotificationEventSubscription = (
  workspaceId: UUIDB64,
  cb: WorkspaceCreateNotificationEventCB,
  type: WorkspaceEventType
) => {
  const existingSubscriptions = get(workspaceEventSubscriptions);
  workspaceEventSubscriptions.set([...existingSubscriptions, { workspace: workspaceId, type, cb }]);
};

const removeWorkspaceCreateNotificationEventSubscription = (
  workspaceId: UUIDB64,
  cb: WorkspaceCreateNotificationEventCB,
  type: WorkspaceEventType
) => {
  const existingSubscriptions = get(workspaceEventSubscriptions);
  const subscriptionsToBeRemoved = existingSubscriptions.filter(
    (s) => s.workspace === workspaceId && s.cb === cb && s.type === type
  );
  workspaceEventSubscriptions.set(existingSubscriptions.filter((s) => !subscriptionsToBeRemoved.includes(s)));
};

// WS connection

const initWS = async (): Promise<WebSocket> => {
  return await new Promise((resolve, reject) => {
    const token = getUserTokenFromBrowser();
    // Only logged in users can access Taiga WebSocket events
    if (!token) {
      reject('Taiga WebSocket connection aborted: No token found');
    }

    let ws = new WebSocket(env.taigaWS);

    const onOpen = () => {
      console.log('Taiga WebSocket open');
      // This command subscribes the user to user events
      const cmd: SignInCommand = {
        command: 'signin',
        token: token as string,
      };
      ws.send(JSON.stringify(cmd));
      ws.removeEventListener('open', onOpen);
    };

    const handleSignInResponse = (event: MessageEvent) => {
      const eventData = JSON.parse(event.data);

      if (!isSignInEvent(eventData)) {
        return;
      }

      ws.removeEventListener('message', handleSignInResponse);

      if (isSignInErrorEvent(eventData)) {
        reject(`Taiga WebSocket connection aborted: Invalid token: ${eventData.action.token}`);
      }

      console.log('Taiga WebSocket sign in successful.');
      resolve(ws);
    };

    ws.addEventListener('open', onOpen);
    ws.addEventListener('message', handleSignInResponse);
  });
};

const onMessage = (event: MessageEvent) => {
  const data = JSON.parse(event.data);

  if (!isEvent(data)) {
    return;
  }

  get(userEventSubscriptions)
    .filter((s) => s.type === data.event?.type)
    .forEach((s) => {
      console.log(`User event of type "${s.type}" received: `, data.event?.content);
      s.cb(data.event?.content);
    });

  get(projectEventSubscriptions)
    .filter((s) => s.type === data.event?.type && s.project === data.event?.content.project)
    .forEach((s) => {
      console.log(`Project event of type "${s.type}" received for project "${s.project}": `, data.event?.content);
      s.cb(data.event?.content);
    });

  get(workspaceEventSubscriptions)
    .filter((s) => s.type === data.event?.type)
    .forEach((s) => {
      console.log(`Workspace event of type "${s.type}" received for workspace "${s.workspace}": `, data.event?.content);
      s.cb(data.event?.content);
    });
};

const connect = async () => {
  console.log('Taiga WebSocket connecting...');
  taigaWS.set(await initWS());
};

const disconnect = () => {
  console.log('Taiga WebSocket disconnecting...');
  const ws = get(taigaWS);
  ws?.close();
};

const scheduleReconnect = () => {
  setTimeout(() => {
    console.log('Taiga WebSocket reconnecting...');
    if (get(taigaWS)?.readyState === WebSocket.CLOSED) {
      connect();
    }
  }, RECONNECT_INTERVAL);
  console.log('Taiga WebSocket reconnect scheduled...');
};

const sendCommand = (cmd: TaigaAction): void => {
  const ws = get(taigaWS);

  if (!ws) {
    console.error('Taiga WebSocket not instantiated.');
    return;
  }

  ws.send(JSON.stringify(cmd));
};

// Users

const subscribeToGeneralInformationChangeEvents = (cb: WorkspaceGeneralInformationUpdateEventCB) => {
  const existingSubscriptions = get(userEventSubscriptions);
  userEventSubscriptions.set([...existingSubscriptions, { type: GENERAL_INFORMATION_UPDATE_EVENT, cb }]);
};

const unsubscribeFromGeneralInformationChangeEvents = (cb: WorkspaceGeneralInformationUpdateEventCB) => {
  const existingSubscriptions = get(userEventSubscriptions);
  userEventSubscriptions.set(
    existingSubscriptions.filter((s) => s.type !== GENERAL_INFORMATION_UPDATE_EVENT && s.cb !== cb)
  );
};

const subscribeToProjectStatusDataChangeEvents = (cb: ProjectStatusDataUpdateEventCB) => {
  const existingSubscriptions = get(userEventSubscriptions);
  userEventSubscriptions.set([...existingSubscriptions, { type: PROJECT_STATUS_DATA_UPDATE_EVENT, cb }]);
};

const unsubscribeFromProjectStatusDataChangeEvents = (cb: ProjectStatusDataUpdateEventCB) => {
  const existingSubscriptions = get(userEventSubscriptions);
  userEventSubscriptions.set(
    existingSubscriptions.filter((s) => s.type !== PROJECT_STATUS_DATA_UPDATE_EVENT && s.cb !== cb)
  );
};

//Create notification

const subscribeToCreateNotificationEvents = (cb: CreateNotificationEventCB) => {
  const existingSubscriptions = get(userEventSubscriptions);
  userEventSubscriptions.set([...existingSubscriptions, { type: CREATE_NOTIFICATION_EVENT, cb }]);
};

const unsubscribeFromCreateNotificationEvents = (cb: CreateNotificationEventCB) => {
  const existingSubscriptions = get(userEventSubscriptions);
  userEventSubscriptions.set(existingSubscriptions.filter((s) => s.type !== CREATE_NOTIFICATION_EVENT && s.cb !== cb));
};

// Project stories update

const subscribeToProjectStoriesUpdateEvents = (cb: ProjectStoriesUpdateEventCB) => {
  const existingSubscriptions = get(userEventSubscriptions);
  userEventSubscriptions.set([...existingSubscriptions, { type: PROJECT_STORIES_UPDATE_EVENT, cb }]);
};

const unsubscribeFromProjectStoriesUpdateEvents = (cb: ProjectStoriesUpdateEventCB) => {
  const existingSubscriptions = get(userEventSubscriptions);
  userEventSubscriptions.set(
    existingSubscriptions.filter((s) => s.type !== PROJECT_STORIES_UPDATE_EVENT && s.cb !== cb)
  );
};

// Projects

const subscribeToProjectEvents = (projectID: UUIDB64) => {
  const cmd: ProjectSubscribeCommand = {
    command: 'subscribe_to_project_events',
    project: projectID,
  };
  sendCommand(cmd);
};

const unsubscribeFromProjectEvents = (projectID: UUIDB64) => {
  const cmd: ProjectUnsubscribeCommand = {
    command: 'unsubscribe_from_project_events',
    project: projectID,
  };
  sendCommand(cmd);
};

// Workspace
const subscribeToWorkspaceEvents = (workspaceID: UUIDB64) => {
  const cmd: WorkspaceSubscribeCommand = {
    command: 'subscribe_to_workspace_events',
    workspace: workspaceID,
  };
  sendCommand(cmd);
};

const unsubscribeFromWorkspaceEvents = (workspaceID: UUIDB64) => {
  const cmd: WorkspaceUnsubscribeCommand = {
    command: 'unsubscribe_from_workspace_events',
    workspace: workspaceID,
  };
  sendCommand(cmd);
};

// Story Tags

const subscribeToProjectStoryTagUpdateEvents = (projectID: UUIDB64, cb: ProjectUpdateStoryTagEventCB) => {
  addProjectEventSubscription(projectID, cb, UPDATE_STORY_TAG_EVENT);
};

const unsubscribeFromProjectStoryTagUpdateEvents = (projectID: UUIDB64, cb: ProjectUpdateStoryTagEventCB) => {
  removeProjectEventSubscription(projectID, cb, UPDATE_STORY_TAG_EVENT);
};

const subscribeToProjectStoryTagDeleteEvents = (projectID: UUIDB64, cb: ProjectDeleteStoryTagEventCB) => {
  addProjectEventSubscription(projectID, cb, DELETE_STORY_TAG_EVENT);
};

const unsubscribeFromProjectStoryTagDeleteEvents = (projectID: UUIDB64, cb: ProjectDeleteStoryTagEventCB) => {
  removeProjectEventSubscription(projectID, cb, DELETE_STORY_TAG_EVENT);
};

const subscribeToProjectStoryTagCreateEvents = (projectID: UUIDB64, cb: ProjectCreateStoryTagEventCB) => {
  addProjectEventSubscription(projectID, cb, CREATE_STORY_TAG_EVENT);
};

const unsubscribeFromProjectStoryTagCreateEvents = (projectID: UUIDB64, cb: ProjectCreateStoryTagEventCB) => {
  removeProjectEventSubscription(projectID, cb, CREATE_STORY_TAG_EVENT);
};

// Project update

const subscribeToProjectUpdateEvents = (projectID: UUIDB64, cb: ProjectUpdateEventCB) => {
  addProjectEventSubscription(projectID, cb, PROJECTS_UPDATE_EVENT);
};

const unsubscribeFromProjectUpdateEvents = (projectID: UUIDB64, cb: ProjectUpdateEventCB) => {
  removeProjectEventSubscription(projectID, cb, PROJECTS_UPDATE_EVENT);
};

// Project milestones update

const subscribeToProjectMilestonesUpdateEvents = (projectID: UUIDB64, cb: ProjectMilestonesUpdateEventCB) => {
  addProjectEventSubscription(projectID, cb, PROJECT_MILESTONES_UPDATE_EVENT);
};

const unsubscribeFromProjectMilestonesUPdateEvents = (projectID: UUIDB64, cb: ProjectMilestonesUpdateEventCB) => {
  removeProjectEventSubscription(projectID, cb, PROJECT_MILESTONES_UPDATE_EVENT);
};

// Workspace

const subscribeToWorkspaceCreateNotificationEvents = (workspaceId: UUIDB64, cb: WorkspaceCreateNotificationEventCB) => {
  addWorkspaceCreateNotificationEventSubscription(workspaceId, cb, WORKSPACE_CREATE_NOTIFICATION_EVENT);
};

const unsubscribeFromWorkspaceCreateNotificationEvents = (
  workspaceId: UUIDB64,
  cb: WorkspaceCreateNotificationEventCB
) => {
  removeWorkspaceCreateNotificationEventSubscription(workspaceId, cb, WORKSPACE_CREATE_NOTIFICATION_EVENT);
};

// Message handling

taigaWS.subscribe((ws) => {
  if (!ws) {
    return;
  }

  ws.onerror = (e: Event) => {
    console.error('Taiga WebSocket error:', e);
    ws?.close();
    scheduleReconnect();
  };

  ws.onclose = (e: CloseEvent) => {
    console.log('Taiga WebSocket closed:', e);
    scheduleReconnect();
  };

  ws.onmessage = onMessage;
});

export default {
  connect,
  disconnect,
  // User events
  subscribeToGeneralInformationChangeEvents,
  unsubscribeFromGeneralInformationChangeEvents,
  subscribeToProjectStatusDataChangeEvents,
  unsubscribeFromProjectStatusDataChangeEvents,
  subscribeToProjectStoriesUpdateEvents,
  unsubscribeFromProjectStoriesUpdateEvents,
  subscribeToCreateNotificationEvents,
  unsubscribeFromCreateNotificationEvents,
  // Project events
  subscribeToProjectEvents,
  subscribeToProjectUpdateEvents,
  unsubscribeFromProjectUpdateEvents,
  unsubscribeFromProjectEvents,
  subscribeToProjectMilestonesUpdateEvents,
  unsubscribeFromProjectMilestonesUPdateEvents,
  // Workspace events
  subscribeToWorkspaceCreateNotificationEvents,
  unsubscribeFromWorkspaceCreateNotificationEvents,
  subscribeToWorkspaceEvents,
  unsubscribeFromWorkspaceEvents,
  // Story tags
  subscribeToProjectStoryTagUpdateEvents,
  unsubscribeFromProjectStoryTagUpdateEvents,
  subscribeToProjectStoryTagDeleteEvents,
  unsubscribeFromProjectStoryTagDeleteEvents,
  subscribeToProjectStoryTagCreateEvents,
  unsubscribeFromProjectStoryTagCreateEvents,
};

import taigaEventStore from '$lib/stores/taigaEventStore';
import { getLogoutUrl } from '$lib/utils/redirect';

const logOut = () => {
  deleteAllCookies();
  // NOTE: Disconnecting Taiga should happen after deleting
  // the cookies, since it's reconnection logic depends on it
  taigaEventStore.disconnect();
  window.location.href = getLogoutUrl();
};

export const useTokenInterceptors = (instance: any) => {
  instance.interceptors.request.use(
    async (config: any) => {
      const token = getUserTokenFromBrowser();
      if (token) {
        config.headers.Authorization = `Token ${token}`;
        config.headers['Content-Type'] = 'application/json';
      }
      return config;
    },
    (error: any) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      //We should fix backend to return proper message and replace this with error.message === constant
      if (error.response.status === 401) {
        logOut();
      }
      return Promise.reject(error);
    }
  );
};

export const useTaigaInterceptors = (instance: any) => {
  instance.interceptors.request.use(
    async (config: any) => {
      const token = getUserTokenFromBrowser();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers['Content-Type'] = 'application/json';
      }
      return config;
    },
    (error: any) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      //We should fix backend to return proper message and replace this with error.message === constant
      if (error.response.status === 401) {
        logOut();
      }
      return Promise.reject(error);
    }
  );
};

export const useMLInterceptors = (instance: any) => {
  instance.interceptors.request.use(
    async (config: any) => {
      const token = getUserTokenFromBrowser();
      if (token) {
        // config.headers.Authorization = `Bearer ${token}`;
        config.headers['Content-Type'] = 'application/json';
      }
      return config;
    },
    (error: any) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      //We should fix backend to return proper message and replace this with error.message === constant
      if (error.response.status === 401) {
        logOut();
      }
      return Promise.reject(error);
    }
  );
};

function setCookie(name: string, value: string, daysToExpire: number) {
  const date = new Date();
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
}

function deleteAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    setCookie(name, '', -1);
  }
}

export const getUserTokenFromBrowser = () => {
  const token = document.cookie.split('; ').find((row) => row.startsWith('user-token='));
  if (token) {
    return token.split('=')[1];
  }
  return null;
};

import axios, { type AxiosInstance } from 'axios';

import { env } from '$src/env';
import { useMLInterceptors, useTaigaInterceptors, useTokenInterceptors } from './interceptors';

const ai8Base: AxiosInstance = axios.create({
  baseURL: env.baseUrl,
});
const defaultBase: AxiosInstance = axios.create({
  baseURL: env.baseUrl,
});
const taigaApi: AxiosInstance = axios.create({
  baseURL: env.taigaAPI,
});
const mlAPI: AxiosInstance = axios.create({
  baseURL: env.mlAPI,
});

useTokenInterceptors(ai8Base);
useTaigaInterceptors(taigaApi);
useMLInterceptors(mlAPI);

const AXIOS_METHODS = ['request', 'get', 'delete', 'head', 'options', 'post', 'put', 'patch'];

const useResponse = async (axiosFn: any, defaultData: any) => {
  const promise = axiosFn instanceof Promise ? axiosFn : axiosFn();

  return Promise.resolve(promise)
    .then((response) => {
      const data = response.data ?? defaultData ?? {};
      return { data, response, error: null };
    })
    .catch((error) => {
      return { data: defaultData ?? {}, response: {}, error };
    });
};

const ai8Api: any = createApiFromAxiosBase(ai8Base);
const baseApi: any = createApiFromAxiosBase(defaultBase);
const mlApi: any = createApiFromAxiosBase(mlAPI);

function createApiFromAxiosBase(instance: AxiosInstance) {
  return AXIOS_METHODS.reduce((acc, axiosMethod) => {
    const fn = (...args: any) => useResponse((instance as any)[axiosMethod](...args), {});

    return { ...acc, ...{ [axiosMethod]: fn } };
  }, {});
}

export { ai8Api, ai8Base, baseApi, mlApi, taigaApi, useResponse };
